import type { GetStaticPaths, GetStaticProps } from 'next'
import { groq } from 'next-sanity'
import {
  getRouteByPath,
  getClient,
  getSiteConfiguration,
} from '@magal/services/sanity-service'
import { getShopifyData } from '@magal/services/shopify-service'
import { generateLocalePaths } from '@magal/utils'
import { PageBuilderSection, PageResponse } from '@magal/models'
import { DefaultPage } from '@magal/page'
import { captureException, withScope } from '@sentry/nextjs'
import { REVALIDATE_NOT_FOUND, REVALIDATE_PAGE } from '@magal/configs'

export const getStaticPaths: GetStaticPaths = async ({ defaultLocale }) => {
  const paths: string[] = await getClient(false).fetch(
    groq`*[!(_id in path('drafts.**')) && defined(path.current)][].path.current`,
  )
  return {
    paths: generateLocalePaths(paths, defaultLocale),
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  PageResponse<PageBuilderSection[]>
> = async ({ params, locale, preview = false }) => {
  const { paths } = params

  if (typeof paths === 'string') {
    throw new Error('paths must be passed as an array. Received string')
  }

  const path = `/${paths ? paths.join('/') : ''}`

  const pageProps = await (async () => {
    const pageData = await getRouteByPath(path, locale, preview)

    if (!pageData) {
      return null
    }

    const shopifyData = await getShopifyData(locale, pageData.pageBuilder)

    return {
      ...pageData,
      shopifyData,
    }
  })()

  if (!pageProps) {
    withScope(function (scope) {
      scope.setTag('sampleRate', 'low')
      captureException(new Error(`Cannot fetch pageProps for ${path}`))
    })

    return {
      notFound: true,
      revalidate: REVALIDATE_NOT_FOUND,
    }
  }

  const siteConfigurationRes = await getSiteConfiguration(locale, preview)

  if (siteConfigurationRes.status === 'ERROR') {
    const exception = new Error(
      `[[...paths]] Sanity getSiteConfiguration function error ${JSON.stringify(
        siteConfigurationRes.errors,
      )}`,
    )
    captureException(exception)
    throw exception
  }

  const { data: siteConfiguration } = siteConfigurationRes

  return {
    props: {
      ...pageProps,
      siteConfiguration,
    },
    revalidate: REVALIDATE_PAGE,
  }
}

export default DefaultPage
