import { PageBuilderSection, PageResponse } from '@magal/models'
import { PageBuilder } from '@magal/page-builder'
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { seoImageLoader } from '@magal/components'

export const DefaultPage: NextPage<PageResponse<PageBuilderSection[]>> = ({
  pageBuilder,
  shopifyData,
  seo,
}) => {
  const { asPath } = useRouter()
  const fullUrl = `${process.env['BASE_URL']}${asPath}`

  const seoImage = seoImageLoader(seo?.ogImage, seo?.ogAltImage)

  return (
    <div>
      <NextSeo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        canonical={fullUrl}
        openGraph={{
          url: fullUrl,
          type: 'website',
          title: seo?.ogTitle,
          description: seo?.ogDescription,
          images: [...(seoImage ? [seoImage] : [])],
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
      />
      {pageBuilder?.map((section, index) => {
        return (
          <PageBuilder
            section={{ ...section, priority: index === 0 }}
            shopifyData={shopifyData}
            key={`${section._type}-${index}`}
          />
        )
      })}
    </div>
  )
}
